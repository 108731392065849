import React from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  Typography,
  Link,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
// import { faTwitter } from '@fortawesome/free-brands-svg-icons';
// import { faYoutube } from '@fortawesome/free-brands-svg-icons';
// import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

const Copyright = () => {
  return (
    <Typography variant="subtitle1" sx= {{ textAlign:'center' }}>
      All rights reserved by bitakhoshnood.com
    </Typography>
  );
};

const Footer = () => {
  return (
    <footer
      sx={{
        display: 'flex',
        justifyContent: 'start',
        bottom: 0,
        width: '100%',
        padding: 1,
        position: 'fixed',
        hieght: '100px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          width: '100%',
          gap: 1,
        }}
      >
        <List
          orientation="horizontal"
          wrap
          sx={{
            display: 'flex',
            // backgroundColor: '#454545',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            width: { xs:'100%', md:'50%'},
            padding: 0,
          }}
        >
          <ListItem sx={{ width: { xs: '50%', md: '50%' } }}>
            <List>
              <ListItem>
                <ListItemButton href="/Home">Home</ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton href="/Biography">Biography</ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton href="/Concerts">Concerts</ListItemButton>
              </ListItem>
            </List>
          </ListItem>
          <ListItem sx={{ width: { xs: '50%', md: '50%' } }}>
            <List>
              <ListItem>
                <ListItemButton href="/Art">Art</ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton href="/Videos">Videos</ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton href="/News">News</ListItemButton>
              </ListItem>
            </List>
          </ListItem>
        </List>
        <Box
          variant="soft"
          size="sm"
          sx={{
            display: 'flex',
            // backgroundColor: '#454554',
            flexDirection: { xs: 'row', md: 'row' },
            width: { xs: '100%', md: '40%' },
            gap: 2,
            justifyContent: { xs:'center', md:'start' },
            padding: 2,
            paddingLeft: {xs: 0, md:4},
          }}
        >
          <Link color="#ffffff" href="https://www.instagram.com/bitakhoshnood">
            <FontAwesomeIcon icon={faInstagram} size="2xl" />
          </Link>
          {/* <Link color="#ffffff" href="https://www.instagram.com/bitakhoshnood">
          <FontAwesomeIcon icon={faYoutube} size="2xl" />
          </Link> */}
          {/* <FontAwesomeIcon icon={faTwitter} size="2xl" /> */}
          {/* <FontAwesomeIcon icon={faLinkedin} size="2xl" /> */}
          <Link color="#ffffff" href="mailto:info@bita.uk">
          <FontAwesomeIcon icon={faEnvelope} size="2xl" />
          </Link>
        </Box>
      </Box>
      <Copyright />
    </footer>
  );
};

export default Footer;
