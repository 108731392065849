import React from 'react';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Container,
  Typography,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';

const Art = () => {
  return (
    <Box sx={{ display: 'flex', padding: 4 }}>
      <Container
        sx={{
          mt: 0,
          mb: 4,
          padding: 0,
          display: 'flex',
          position: 'relative',
          flexDirection: 'column',
          alignItems: 'center',
          rowGap: 2,
          width: '100%',
        }}
      >
        <Helmet>
          <title>Art</title>
        </Helmet>
        <Typography variant="h4">Art</Typography>

        <div class="entry-content">
          <h4 class="wp-block-heading">The War and the Color</h4>
          <Typography>
            An exhibition by Anoosheh Djam, the manager of Djam Art Academy, and
            her young adolescent trainees in January 2016 in Niavaran Artistic
            Creations Foundation, Niavaran Cultural Centre.
          </Typography>
          <Card
            sx={{
              maxWidth: 400,
              float: 'left',
              mr: { sx: 0, md: 4 },
              mt: 2,
              mb: 2,
            }}
          >
            <CardActionArea>
              <CardMedia
                component="img"
                height="auto"
                image="/images/bita_war_color.jpeg"
                alt="green iguana"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  The War and the Color
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  The War & The Color According to the latest research, the
                  world is a safer place to live today than in previous
                  centuries.
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
          <p>
            History has witnessed all-out wars, and World War II can be
            considered as the last full-scale war. Most of the wars after that
            time (but not all of them) were civil wars and factional wars.
          </p>
          <p>
            Whatever the type of war, whether limited or all-out, short or long,
            civil or against foreigners, cold or warm, and so on, it has drastic
            and irretrievable consequences.
          </p>
          <p>
            War connects hundreds, thousands and even millions of lives to the
            fear of death, anxiety of an unclear future, migration and
            homelessness, loneliness and defencelessness, separation and loss,
            poverty, starvation, illness, and much more.
          </p>
          <p>
            {' '}
            For Iranian people, war is not just an abstract and unfamiliar word
            in dictionaries, history books or movies. All of us have experienced
            the bitterness of war based on our conditions and our age.
            Fortunately, our children have just heard about the war in our
            personal and collective memories, and they haven’t experienced it
            themselves.
          </p>

          <Card
            sx={{
              maxWidth: 400,
              float: 'right',
              ml: { sx: 0, md: 4 },
              mt: 2,
              mb: 2,
            }}
          >
            <CardActionArea>
              <CardMedia
                component="img"
                height="auto"
                image="/images/draw.jpeg"
                alt="green iguana"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Drawing About War
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  All of us have experienced the bitterness of war based on our
                  conditions and our age.
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
          <p>
            Now the question is whether we must familiarize our children and
            young adolescents with such a bitter concept.
            <em>
              In other words, with so many topics around us, why should children
              draw pictures about the war?{' '}
            </em>
            <p>
              The answer is short and to the point: a group of children in other
              parts of the world have forgotten that they are children and they
              are struggling with things that we can’t even think about.{' '}
              <em>Do you remember the little Syrian refugees? </em>
            </p>
            <p>
              The combination of children and young adolescents’ innocent,
              honest, and fearless perspectives and the language of art has a
              message for us:
            </p>
            <p>
              <em>Dear adults! </em>{' '}
              <em>
                We are young, but we care about the world. What about you? Do
                you care about the world?
              </em>
            </p>
          </p>
          <div>
            <Card sx={{ maxWidth: 400 }}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="auto"
                  image="/images/The-War-and-the-Color.jpeg"
                  alt="green iguana"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    The War & The Color
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Djam Art Academi Presents, 22- 29 January 2014 Gallery No.2,
                    Niavaran Cultural Center, Tehran
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </div>
        </div>
      </Container>
    </Box>
  );
};

export default Art;
