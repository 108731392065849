import React, { Suspense, lazy } from 'react';
import ReactLoading from 'react-loading';

const Main = lazy(() => import('./Main'));

const Home = () => {
  return (
    <Suspense
      fallback={
        <div style={{ width: '100%', height: '90vh', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#454545' }}>
          <ReactLoading type="spokes" color="#ffffff" height={150} width={150} />
        </div>
      }
    >
      <Main />
    </Suspense>
  );
};

export default Home;
