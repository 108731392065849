import { Box, Container, CssBaseline, Typography } from '@mui/material';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import Grid from '@mui/material/Grid';
import MainFeaturedPost from './components/MainFeaturedPost';
import Posts from './components/Posts';

const posts = [
  {
    title: "Congratulations to Bita, MiSST's Young Musician of the Year",
    date: '25 Apr 2024',
    description:
      "This years MiSST Gala was more special than others, as our very own Bita in Year 13 was awarded the title of MiSST’s Young Musician of the Year. Her composition also won the composition competition, judged by Andrew Lloyd Webber, so was selected to be performed with a full orchestra at the Gala. We wish Bita a huge congratulations on her achievement.",
    image: '/images/news/whitefield.jpeg',
    imageLabel: "Whitefield school's news",
    link: 'https://www.whitefield.barnet.sch.uk/104/latest-news/post/25/congratulations-to-bita-missts-young-musician-of-the-year',
    reference: 'Whitefield School',
    referenceLink: 'https://www.whitefield.barnet.sch.uk/104/latest-news/post/25/congratulations-to-bita-missts-young-musician-of-the-year',
  },
  {
    title: 'The lsso returns to the Hall to perform music inspired by folklore and fairy tales',
    date: '15 Apr 2024',
    description:
      "Students from Guildhall School join London’s premiere youth orchestra as soloists in Bartók’s psychological thriller Bluebeard’s Castle, conducted by Guildhall’s Head of Opera, Dominic Wheeler. The orchestra’s biennial venture into the artform also brings to life the Czech countryside in Janáček’s luminous interludes from The Cunning Little Vixen. Originally written for electronics, the needle drops with Anna Meredith’s stomping Nautilus, conducted by the LSSO’s new Conductor in Residence, Enyi Okpara.",
    image: '/images/news/lsso.jpg',
    imageLabel: 'Simon Cowell',
    link: 'https://www.barbican.org.uk/whats-on/2024/event/london-schools-symphony-orchestra-0',
    reference: 'Barbican',
    referenceLink: 'https://www.barbican.org.uk',
  },
  {
    title: 'Andrew Lloyd Webber charity raises money for disadvantaged schools',
    date: '26 May 2023',
    description:
      "A charity backed by Andrew Lloyd Webber has raised vital funds thanks to a star-studded event. Britain's Got Talent judge Simon Cowell, Strictly Come Dancing legend Arlene Phillips and Loose Women's Janet Street-Porter all attended a special concert.",
    image: '/images/news/the-sun-misst.jpeg',
    imageLabel: 'Simon Cowell',
    link: 'https://www.thesun.co.uk/tv/22483725/andrew-lloyd-webber-simon-cowell/',
    reference: 'The Sun',
    referenceLink: 'https://www.thesun.co.uk',
  },
  {
    title: "The Lady Mayoress's Musical Feast",
    date: '24 April 2023',
    description:
      'Celebrating the power of Music to inspire, educate and change young lives in Mansion House',
      image: '/images/news/milo-concert.jpg',
    imageLabel: 'Milo',
    link: 'https://www.eventbrite.co.uk/e/the-lady-mayoresss-musical-feast-tickets-655828890867',
    reference: 'Eventbrite',
    referenceLink: 'https://www.eventbrite.co.uk',
  },  {
    title: "MiSST 10th Anniversary Concert",
    date: '5 Jan 2023',
    description:
      'MiSST’s 10th Anniversary Concert took place at Theatre Royal Drury Lane on Monday 24 April 2023. 10 years. 29 schools. 20,000 children. Performers included MiSST alumni and Ambassador Deronne White, now a member of the Chineke! Orchestra, Jess Gillam MBE, The Kanneh-Mason Family, Andrew Lloyd Webber and Jason Donovan. 3 young musicians received MiSST Musician of the Year awards. Each will receive a bursary to further their music education.',
    image: '/images/news/misst-10th-concert.jpeg',
    imageLabel: 'MiSST 10th',
    link: 'https://andrewlloydwebberfoundation.com/blog/misst-10th-anniversary-concert',
    reference: 'Andrew Lloyd Webber Foundation',
    referenceLink: 'https://andrewlloydwebberfoundation.com',
  },  {
    title: "Music In Secondary Schools Trust",
    date: '15 April 2022',
    description:
      'The Music In Secondary Schools Trust was established with funding from the Andrew Lloyd Webber Foundation and the Charles Wolfson Trust and promises every child an entitlement to study a classical musical instrument on entry into secondary school, as well as tuition and performance opportunities.',
    image: '/images/news/MiSST 9th Annual Concert.jpeg',
    imageLabel: 'MiSST 9th',
    link: 'https://andrewlloydwebberfoundation.com/content/music-in-secondary-schools-trust',
    reference: 'Andrew Lloyd Webber Foundation',
    referenceLink: 'https://andrewlloydwebberfoundation.com',
  },{
    title: "Fajr International Music Festival",
    date: '16 January 2017',
    description:
    'The third day of the 32nd Fajr International Music Festival hosted Mehr Orchestra, Namira Choir conducted by Farhad Harati, Kara Wind Orchestra led by Iranian conductor Bardia Kiaras and Iran Philharmonic Choir by conductor Alireza Shafaqinejad at Vahdat and Roudaki Halls on Sunday.',
    image: '/images/news/Fajr Festival.jpeg',
    imageLabel: 'Fajr',
    link: 'http://en.mehrnews.com/photo/122729/Fajr-Fest-hosts-orchestras-philharmonic-choirs',
    reference: 'Mehr News',
    referenceLink: 'http://en.mehrnews.com',
  },{
    title: 'Picture of the day',
    date: '17 January 2017',
    description:
    'Farhad Harati conducts Namira, a choral ensemble of young musicians, in a performance about environmental issues during the 32nd Fajr International Music Festival at Tehran’s Vahdat Hall on January 16, 2017. (Honaronline/Bahareh Asadi)',
    image: '/images/news/Picture of the day.png',
    imageLabel: 'Fajr',
    link: 'https://www.tehrantimes.com/news/410172/Picture-of-the-day',
    reference: 'Tehran Times News',
    referenceLink: 'https://www.tehrantimes.com',
  },{
    title: "Replay of Cheshmazar’s ‘Rain of Love’",
    date: '4 January 2016',
    description:
      "A one-day performance by Naser Cheshmazar, acclaimed musician, composer and orchestra conductor last October received a warm welcome by music lovers. However, numerous enthusiasts were unable to attend that event as the tickets were all sold out. Cheshmazar is taking the stage once more on January 8, for a replay of his successful concert ‘Rain of Love’ at Tehran’s iconic Milad Tower, Mehr News Agency reported.",
    image: '/images/news/Rain of Love.jpeg',
    imageLabel: 'Rain of Love',
    link: 'https://financialtribune.com/articles/art-and-culture/33457/replay-of-cheshmazar-s-rain-of-love',
    reference: 'Financial Tribune',
    referenceLink: 'https://financialtribune.com',
  },{
    title: "The Sacred Earth",
    date: '14 Dec 2015',
    description:
      "The Iranian Naamira Choir group is providing an “international touch” to its upcoming performance that focuses on environmental and social issues. Issues of war, migration, extinction of animal species, global warming, greenhouse effect, air contamination and deadly diseases like HIV will be the themes of the performance, titled ‘The Sacred Earth’, said Farhad Harati, head of the choir, reports Honaronline.",
    image: '/images/news/TheScaredEarth.jpeg',
    imageLabel: 'Naamira',
    link: 'https://financialtribune.com/articles/art-and-culture/32076/the-sacred-earth-at-vahdat',
    reference: 'Financial Tribune',
    referenceLink: 'https://financialtribune.com',
  },{
    title: "Stars back Andrew Lloyd Webber call for music education funding",
    date: '30 Nov 2023',
    description:
      "Ed Sheeran and Dua Lipa among signatories of letter to Rishi Sunak and Keir Starmer calling for funds for music in disadvantaged schools",
    image: '/images/news/stars-back-andrew-lloyd-webber.jpeg',
    imageLabel: 'Andrew Lloyd Webber',
    link: 'https://www.theguardian.com/education/2023/nov/30/stars-back-andrew-lloyd-webber-call-for-music-education-funding',
    reference: 'The Guardian',
    referenceLink: 'https://www.theguardian.com',
  },{
    title: "Andrew Lloyd Webber forms ‘supergroup’ to call on government for music education support",
    date: '2 Dec 2023',
    description:
      "In a world that feels more divided than at any point in my lifetime, and with conflict raging around the world with incalculable consequences, music has a unique ability to unite",
    image: '/images/news/andrew-lloyd-webber-supergroup.jpeg',
    imageLabel: 'Andrew Lloyd Webber',
    link: "https://www.independent.co.uk/arts-entertainment/music/news/andrew-lloyd-webber-music-education-charity-b2456757.html",
    reference: 'Independent',
    referenceLink: 'https://www.independent.co.uk',
  },{
    title: "Music stars back Andrew Lloyd Webber in call for more music education funding",
    date: '4 Dec 2023',
    description:
      "Dua Lipa, Liam Gallagher, Ed Sheeran, Coldplay and Royal Blood have all co-signed a letter from Andrew Lloyd Webber to Rishi Sunak and Keir Starmer calling for more funding for music in schools - and in particular to support the work of the Music In Secondary Schools Trust",
    image: '/images/news/music-stars-back.png',
    imageLabel: 'Music Stars Back',
    link: "https://completemusicupdate.com/music-stars-back-andrew-lloyd-webber-in-call-for-more-music-education-funding/",
    reference: 'Complete Music Update',
    referenceLink: 'https://completemusicupdate.com',
  },{
    title: "London Schools Symphony Orchestra in Barbican Hall",
    date: '10 Jan 2024',
    description: "Romantic declarations and dark deeds: the LSSO returns with a sensational programme of music by Rachmaninov, Berlioz and Ethel Smyth.",
    image: '/images/news/lsso-barbican.jpeg',
    imageLabel: 'London Schools Symphony Orchestra',
    link: "https://www.barbican.org.uk/whats-on/2024/event/london-schools-symphony-orchestra",
     reference: 'Barbican Hall',
    referenceLink: 'https://www.barbican.org.uk/',
   }
].sort((a,b)=>{
  return new Date(b.date) - new Date(a.date);;
});

const News = () => {
  return (
    <Box sx={{ display: 'flex' }}>
      <Container
        sx={{
          mt: 4,
          padding: 0,
          display: 'flex',
          position: 'relative',
          flexDirection: 'column',
          alignItems: 'center',
          rowGap: '14px',
          width: '100%',
        }}
      >
        <Helmet>
          <title>News</title>
        </Helmet>
        <Typography variant="h4">News</Typography>
      <CssBaseline />
      <Container maxWidth="lg">
        <main>
          <MainFeaturedPost post={posts[0]} />
          <Grid container spacing={4} marginBottom={8}>
            {posts.slice(1).map((post) => (
              <Posts key={post.title} post={post} />
            ))}
          </Grid>
        </main>
      </Container>

     </Container>
    </Box>
  );
};

export default News;
