import * as React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Grid,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Link,
  CardActions,
} from '@mui/material';
function Posts(props) {
  const { post } = props;

  return (
    <Grid item xs={12} md={3} className='grid-row'>
        <Card >
          <div>

          <CardMedia
            sx={{ height: 180 }}
            image={post.image}
            title={post.title}
            />
          <CardContent sx={{  alignSelf: 'flex-end' }}>
            <Typography gutterBottom variant="h5" component="div">
              {post.title}
            </Typography>
            {/* <div >  */}
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ overflow: 'hidden', textOverflow: 'ellipsis', height: 100 }}
              >
              {post.description}
              {'...'}
            </Typography>
            {post.reference&&
            <Typography
            variant="subtitle1"
            zIndex={2}
            color="#858585"
            href={post.referenceLink}
            paragraph
            >
              Ref: {post.reference}
            </Typography>}
          </CardContent>
              </div>
          <CardActions sx={{ backgroundColor: '#fff', justifyContent: 'space-between', alignItems:'end' }}>
            <Typography variant="body2" color="text.secondary">
              {post.date}
            </Typography>
            <Link
              variant="subtitle1"
              color="#858585"
              href={post.link}
              target="_blank"
            >
              {' '}
              Continue reading…{' '}
            </Link>
          </CardActions>
        </Card>
    </Grid>
  );
}

Posts.propTypes = {
  post: PropTypes.shape({
    date: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    imageLabel: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
};

export default Posts;
