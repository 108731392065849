// import { YouTube } from '@mui/icons-material';
import React from 'react';
import {
  Box,
  Typography,
  Card,
  Container,
  CardMedia,
  CardContent,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';

const videosData = [
  {
    video_id: 'LfobaHAg8cI?si=ui3h5AXHWhReVzdv',
    title: 'MiSST 11th Anniversary Concert',
    description: 'The Roundhouse Camden – London',
    param: '2222&end=3419',
    thumbnail: 'https://bitakhoshnood.com/images/bita-misst-10th.jpeg',
  },
  {
    video_id: 'i_VkoEclJKI',
    title: 'MiSST 10th Anniversary Concert',
    description: 'Theatre Royal Drury Lane – London',
    param: '4188&end=4300',
    thumbnail: 'https://bitakhoshnood.com/images/bita-misst-10th.jpeg',
  },
  {
    video_id: 'P27D4oj6Gvk',
    title: 'MiSST 9th Annual Concert',
    description: 'Queen Elizabeth Hall',
    param: '3260&end=4820',
  },
  {
    video_id: 'D-aNQATV5eI',
    title: 'Utopia Multi-Media Concert',
    description: 'Milad Tower 10 – 11 October 2013',
    param: '',
  },
  {
    video_id: 'iqu_rgK0auA',
    title: 'The Voice of Life',
    description: 'Movment 8 – Vahdat Hall 11-12 January 2014',
    param: '',
  },
  {
    video_id: 'FOh6vqJfV6o',
    title: 'PALLETT | A Thousand Tales',
    description: 'Mr. Violet album',
    param: '',
  },
  {
    video_id: 'KrT3VdKTlPE',
    title: 'Nasser Cheshmazar Live in Concert',
    description:
      'The Night with The Rain of Life – Vahdat Hall – 11 October 2015',
    param: '',
  },
  {
    video_id: 'zmZ6cDrb_70',
    title: 'Cheshmazar Concert',
    description:
      'The Night with The Rain of Life – Vahdat Hall – 11 October 2015',
    param: '',
  },
  {
    video_id: 'RxXlmE5s57A',
    title: 'Nasser Cheshmazar Live in Concert',
    description:
      'The Night with The Rain of Life – Vahdat Hall – 11 October 2015',
    param: '',
  },
  {
    video_id: '-S31C9AIzCs',
    title: 'Cheshmazar Concert',
    description:
      'The Night with The Rain of Life – Vahdat Hall – 11 October 2015',
    param: '',
  },
  {
    video_id: 'OGt3lJNhmKg',
    title: 'Cheshmazar Concert',
    description:
      'The Night with The Rain of Life – Vahdat Hall – 11 October 2015',
    param: '',
  },
];
const Videos = () => {
  const YoutubeEmbed = (video) => (
    <Card
      sx={{
        width: { md: '360px' },
        maxWidth: '100%',
        backgroundColor: '#989898',
        color: '#141414',
        borderRadius: 3,
      }}
      key={video.video_id}
    >
      <CardMedia
        component="iframe"
        sx={{
          height: 300,
          maxHeight: { xs: 180, md: 200 },
          maxWidth: { xs: '100%', md: 360 },
          borderWidth: 0,
        }}
        alt={video.title}
        data-thumbnail-src={video.thumbnail}
        src={`https://www.youtube.com/embed/${video.video_id}?start=${video.param}`}
        allowFullScreen
        loading= 'lazy'        
      />
      <CardContent>
        <Typography gutterBottom variant="h6" component="div">
          {video.title}
        </Typography>
        <Typography variant="body2">{video.description}</Typography>
      </CardContent>
    </Card>
  );
  return (
    <Box sx={{ display: 'flex' }}>
      <Container
        sx={{
          mt: 4,
          padding: 0,
          display: 'flex',
          position: 'relative',
          flexDirection: 'column',
          alignItems: 'center',
          rowGap: '14px',
          width: '100%',
        }}
      >
        <Helmet>
          <title>Videos</title>
        </Helmet>
        <Typography variant="h4">Videos</Typography>
        {/* <iframe title="MiSST 10th Anniversary Concert" width="525" height="295" */}
        {/* src="https://www.youtube.com/embed/i_VkoEclJKI?start=3161&amp;feature=oembed" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen=""></iframe>       */}
        <Box
          sx={{
            display: 'flex',
            padding: 4,
            gap: 2,
            justifyContent: 'center',
            flexDirection: { xs: 'column', md: 'row' },
            flexWrap: 'wrap',
          }}
        >
          {videosData.map((video) => (
            <>{YoutubeEmbed(video)}</>
          ))}
        </Box>
      </Container>
    </Box>
  );
};

export default Videos;
