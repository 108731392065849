import React from 'react';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@mui/lab';
import { Typography, useTheme, Link } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

const concertsData = [
  { date: '22 April 2024', title: "The Music in Secondary Schools' Trust 11th Annual Concert", location: 'RoundHouse, London', link: '/news'},
  { date: '15 April 2024', title: "London Schools Symphony Orchestra", location: 'Barbican Hall, London', link: '/news'},
  { date: '14 February 2024', title: "GYA 'Les Miserables' Project with Cameron Mackintosh", location: 'Milton Court Concert Hall, London', link: '/news'},
  { date: '10 January 2024', title: 'London Schools Symphony Orchestra Concert', location: 'Barbican Hall, London', link: '/news'},
  { date: '24 April 2023', title: 'MiSST 10th Anniversary Concert', location: 'Theatre Royal Drury Lane, London', link: '' },
  { date: '17 July 2023', title: 'MiSST Gresham Residential', location: "Gresham College, Holt" },
  { date: '11 July 2022', title: 'MiSST Radley Residential', location: 'Radley College, Oxford' },
  { date: '24 October 2023', title: "Milo, The Lady Mayoress's Musical Feast", location: 'Mansion House, City of London', link: 'https://www.eventbrite.co.uk/e/the-lady-mayoresss-musical-feast-tickets-655828890867' },
  { date: '3 March 2022', title: 'MiSST 9th Annual Concert', location: 'Queen Elizabeth Hall, Southbank Centre, London' },
  { date: '15 January 2017', title: 'Fajr Music Festival', location: 'Vahdat Hall, Tehran' },
  { date: '8 January 2016', title: 'CheshmAzar Concert', location: 'Milad Tower, Tehran' },
  { date: '26 – 27 December 2015', title: 'The Sacred Earth', location: 'Vahdat Hall, Tehran' },
  { date: '11 October 2015', title: 'The Night with The Rain of Life', location: 'Vahdat Hall, Tehran' },
  { date: '4 August 2015', title: 'Naamira’s Night', location: 'Niavaran Cultural Center, Tehran' },
  { date: '25 February 2014', title: 'The Voice of Life', location: 'Hozeh Honari Andisheh Hall, Tehran' },
  { date: '11 - 12 January 2014', title: 'The Voice of Life', location: 'Vahdat Hall, Tehran' },
  { date: '10 – 11 October 2013', title: 'Utopia', location: 'Milad Tower, Tehran' },
  { date: '7 – 9 November 2012', title: 'Pallett Band Concert', location: 'Azadi Tower, Tehran' },
].sort((a,b)=>{
  return new Date(b.date) - new Date(a.date);;
});

const TimelineCard = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <>
    {concertsData.map((c, index) => (
      <TimelineItem position={isMobile?"alternate":"right"} key={index}>
      <TimelineOppositeContent
        sx={{ m: 'auto 0', display: { xs: 'none', md: 'block' } }}
        align="right"
        variant="body2"
        color="text.secondary"
      >
        {c.date}
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineConnector sx={{ bgcolor: 'primary.main' }}/>
        <TimelineDot color="primary" variant={index%2===0?"filled":"outlined"}>
          <MusicNoteIcon />
        </TimelineDot>
        <TimelineConnector sx={{ bgcolor: 'primary.main' }} />
      </TimelineSeparator>
      <TimelineContent sx= {index%2===0? { py: '12px', px:2 } : { py: '12px', px:2.1 }} >
        <Typography variant="h6" component="span">
         <Link href={c.link} target="_blank"> {c.title} </Link>
        </Typography>
        <Typography>{c.location}</Typography>
        <Typography
          variant="body2"
          component="span"
          sx={{ display: { xs: 'block', md: 'none' } }}
        >
          {c.date}
        </Typography>
      </TimelineContent>
    </TimelineItem>

    ))}
    </>
  );
};

export default TimelineCard;
