import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
// import { useLocation } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
  FormControl,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import IconButton from '@mui/material/IconButton';

const Signin = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [data, setData] = React.useState({
    email: '',
    status: 'initial',
  });
  // const location = useLocation()
  // const redirectInUrl = new URLSearchParams(location).get('redirect');
  // const redirect = redirectInUrl ? redirectInUrl : '/';

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Container sx={{ maxWidth: '600px', mt: 3 }}>
      <Helmet>
        <title>Sign In</title>
      </Helmet>
      <Typography variant="h4">Sign in</Typography>
      <form>
        <Box
          component="form"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            maxWidth: '70%',
            gap: 3,
            margin: '20px 0',
          }}
          noValidate
          autoComplete="off"
        >
          <FormControl>
          <OutlinedInput
              sx={{ '--Input-decoratorChildHeight': '45px' }}
              placeholder="mail@provider.com"
              type="email"
              required
              onChange={(event) =>
                setData({ email: event.target.value, status: 'initial' })
              }
              error={data.status === 'failure'}
            />
            {/* {data.status === 'failure' && (
          <FormHelperText
            sx={(theme) => ({ color: theme.vars.palette.danger[400] })}
          >
            Oops! something went wrong, please try again later.
          </FormHelperText>
        )} */}

            {/* {data.status === 'sent' && (
          <FormHelperText
            sx={(theme) => ({ color: theme.vars.palette.primary[400] })}
          >
            You are all set!
          </FormHelperText>
        )} */}
          </FormControl>
          <FormControl variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">
              Password
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={showPassword ? 'text' : 'password'}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>
          <Button
            sx={{ padding: 1, maxWidth: '100px' }}
            variant="contained"
            color="primary"
          >
            Login
          </Button>
        </Box>
      </form>
    </Container>
  );
};

export default Signin;
