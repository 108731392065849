import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';

export default function withRoot(Component) {
  function WithRoot(props) {
    return (
      <React.Fragment>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <Component {...props} />
        </React.Fragment>
    );
  }

  return WithRoot;
}