import React from 'react';
import { makeStyles } from '@mui/styles';

import { Box, Container, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';

const backgroundImage2 = '/images/shadow.jpg';
const backgroundImage1 = '/images/pallett-azadi.jpg';
const backgroundImage3 = '/images/news/misst-10th-concert.jpeg';

const useStyles = makeStyles((theme) => ({
  boxWithImage1: {
    backgroundImage: `url(${backgroundImage1})`,
    backgroundPosition: 'left',
    backgroundSize: 'cover',
    borderRadius: '10px',
    height: '40vh',
    marginBottom: '1rem',
    marginTop: '1rem',
  },
  boxWithImage2: {
    backgroundImage: `url(${backgroundImage2})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundPositionY: { xs: 0, md: '-240px' },
    borderRadius: '10px',
    height: '40vh',
    marginBottom: '1rem',
    marginTop: '1rem',
  },
  boxWithImage3: {
    backgroundImage: `url(${backgroundImage3})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    borderRadius: '10px',
    height: '40vh',
    marginBottom: '1rem',
    marginTop: '1rem',
  },
}));

const description =
  "Explore the musical journey of Bita Khoshnood, a prodigious musician born in Tehran, Iran, in 2005. From her early days at the School of Art and Literature to sharing the stage with Iran's iconic Pallett Band, her trajectory unfolds through rigorous violin training, choral mastery, and collaborations with renowned artists. Witness her dedication and continual growth in pursuit of musical excellence, marking her impact on the global stage.";

function Biography() {
  const classes = useStyles();
  return (
    <Box sx={{ display: 'flex' }}>
      <Container
        sx={{
          mt: 4,
          padding: 0,
          display: 'flex',
          position: 'relative',
          flexDirection: 'column',
          alignItems: 'center',
          rowGap: '14px',
          width: '100%',
        }}
      >
        <Helmet>
          <title>Biography</title>
          <meta name="description" content={description} />
        </Helmet>
        <Typography variant="h4">Biography </Typography>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'column', lg: 'row', xl: 'row' },
            gap: 8,
            padding: 4,
            margin: 0,
          }}
        >
          <Box sx={{ display: 'block', width: '100%', margin: 0, padding: 0 }}>
            <Typography>
              Bita Khoshnood, born on 2nd October 2005 in Tehran, Iran, is a
              talented musician who has made impressive strides in her musical
              journey.
            </Typography>
            <Typography>
              Her musical education commenced in June 2009 when she enrolled at
              the{' '}
              <a href="http://musicschool.irib.ir/">
                School of Art and Literature
              </a>
              . There, she pursued her studies in music, laying the foundation
              for her artistic development. In November 2011, Bita completed the
              Orff course, expanding her understanding of music theory and
              pedagogy.
            </Typography>
            <Typography>
              In December 2011, she embarked on her violin training at the same
              institution, honing her skills in this captivating instrument.
              Bita’s commitment to musical excellence led her to join the
              school’s choir course in October 2012, further enhancing her vocal
              abilities.
            </Typography>
            <Typography>
              In November 2012, Bita experienced a notable milestone in her
              career as she proudly accompanied the{' '}
              <a href="http://pallettmusic.com/">Pallett Band</a> during their
              first <a href="https://bitakhoshnood.com/Concerts/">concert</a> at
              the{' '}
              <a href="https://en.m.wikipedia.org/wiki/Azadi_Tower">
                Azadi Tower
              </a>{' '}
              Hall. This marked her debut appearance alongside the biggest
              boyband in Iran and her participation in their music video for “A
              Thousand Tales.”
            </Typography>
            <Box display= {{ xs: "block", md: "none", lg:"none", xl:"none" }} className={classes.boxWithImage1}/>
            <Typography>
              Continuing her musical education, Bita sought specialized training
              in choral teaching and solfège under the guidance of Mr Farhad
              Harati at his music academy in June 2013. Her involvement in the
              professional choir allowed her to participate in various{' '}
              <a href="https://bitakhoshnood.com/Videos/">performances</a>,
              including the grand production “Utopia” held at the prestigious{' '}
              <a href="https://en.m.wikipedia.org/wiki/Milad_Tower">
                Milad Tower
              </a>
              , as well as “The Voice of Life” at{' '}
              <a href="https://en.m.wikipedia.org/wiki/Vahdat_Hall">
                Vahdat Hall
              </a>
              , one of Tehran’s oldest venues. Bita took great pride in
              accompanying renowned musician Naser Cheshm Azar in his concerts,
              along with the choir group, in addition to six other performances.
            </Typography>
            <Typography>
              To further her proficiency in the violin, Bita began professional
              training with Mrs Marjan Ghanbari Mehr in June 2016, refining her
              technique and musicianship on the instrument.
            </Typography>
            <Box className={classes.boxWithImage2}></Box>
            <Typography>
              In October 2020, Bita continued her musical journey under the
              guidance of Mark Pedus, who specializes in violin instruction.
              This ongoing training has allowed her to further elevate her
              skills and deepen her musical understanding.
            </Typography>
            <Typography>
              Since September 2021, Bita has become a member of the{' '}
              <a href="https://www.misst.org.uk/">
                MiSST (Music in Secondary School Trust)
              </a>{' '}
              symphony orchestra, within Sir Andrew Lloyd Webber's program, joining a collective of talented musicians in
              pursuit of musical excellence. She is now also presented as one of
              MiSST’s student leaders, and is part of their chamber ensemble
              called <a href="https://miloensemble.com/">MiLO</a>, where Bita and 16 other independent young musicians
              plan their own weekly rehearsals and their own performances. As a
              result, she has performed on different venues such as{' '}
              <a href="https://en.wikipedia.org/wiki/Queen_Elizabeth_Hall">
                Southbank Centre Queen Elizabeth Hall
              </a>
              ,{' '}
              <a href="https://www.cityoflondon.gov.uk/about-us/about-the-city-of-london-corporation/mansion-house">
                Mansion House
              </a>{' '}
              and <a href="https://thelane.co.uk/">Theatre Royal Drury Lane</a>.
            </Typography>
            <Box display= {{ xs: "block", md: "none", lg:"none", xl:"none" }} className={classes.boxWithImage3}></Box>
            <Typography>
              Thanks to MiSST, she started her conducting lessons in November
              2021 with Jonathan Gibson, which then developed her as a
              professional student conductor with different performance
              experiences such as MiSST 10th Anniversary, MiSST Radley
              residential and MiSST Gresham residential. Lastly, since November
              2023, she proudly has been a member of The{' '}
              <a href="https://lsso.lgfl.org.uk/main.html">
                LSSO (London Schools Symphony Orchestra)
              </a>
              , managed by the Centre for Young Musicians.{' '}
            </Typography>
            <Typography>
              Bita’s commitment to continuous learning and dedication to her
              craft have contributed to her growth as a musician. With each
              milestone achieved, she continues to embark on new artistic
              endeavours, leaving an indelible mark on the world of music.
            </Typography>
          </Box>
          <Box sx={{ width: { xs: '100%', sm: '400px', md: '400px' } }}>
            <img
              className="context-img"
              src="images/violin.jpeg"
              alt="bita-violin"
            />
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

export default Biography;
